<template>
<div>

  <b-modal id="modal-send" centered hide-footer title-class="h3">
    <template v-slot:modal-title>
      Please confirm the details to send first questionnaire
    </template>
    <div class="d-block border-top border-bottom py-2">
      <p>
        <strong>Parent's details:</strong> {{form.parentFirstName}} {{form.parentLastName}}<br>
        <strong>Email:</strong> {{form.parentEmail}}
      </p>
      <p class="mb-0">
        <strong>Child's first name:</strong> {{form.childFirstName}}
      </p>
    </div>
    <div class="d-block mt-3">
      <div class="d-flex justify-content-between">
        <button class="btn btn-block text-dark btn-lg btn-link" v-on:click="$bvModal.hide('modal-send')">Cancel</button>
        <button class="btn btn-block btn-lg btn-warning ml-1" v-on:click="submit()">Confirm and send</button>
      </div>
    </div>
  </b-modal>

  <div class="container-fluid wrapper-medium mt-3">

    <div class="row mb-3">
      <div class="col-12 col-md-6 mb-3">
          <b-link class="btn btn-link btn-sm pl-0" to="/dashboard"><span class="icon icon-th-large mr-2 pl-0"></span>Dashboard</b-link>
          <h2 class="anim-load1 mt-2 mb-3">Create new Parent & Child profile</h2>

          <div class="card mb-3">
            <div class="card-body">
              <Credits :creditsAv="creditsAv" :displayInfo="false" />
            </div>
          </div>

          <ol class="list-standard">
            <li>Enter parent and child details to send the TAYC-R questionnaire.</li>
            <li>A link to the questionnaire will be sent to the parent's email address. Once they complete the questionnaire, your organisation will be charged 1 credit.</li>
            <li>You will then be able to view their results and create followup questionnaires.</li>
            <li>Please advise Parents to check their junk email folder.</li>
          </ol>
      </div>
      <div class="col-12 col-md-6">
        <div class="card mb-3">
          <div class="card-body">
            <ValidationObserver ref="form">
              <b-form @submit.prevent="submitChecks" class="mb-3">

                <div class="form-group" ref="parentFirstName">
                  <label for="parentFirstNameInput">Parent's first name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="Parent's first name">
                    <input v-model="form.parentFirstName" type="text" placeholder="" autocomplete="off"  name="parentFirstNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="parentLastName">
                  <label for="parentLastNameInput">Parent's last name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="parentLastName">
                    <input v-model="form.parentLastName" type="text" placeholder="" autocomplete="off"  name="parentLastNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="parentEmail">
                  <label for="parentEmailInput">Parent's email address</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required|email" name="Parent's email address">
                    <input v-model="form.parentEmail" type="text" placeholder="" autocomplete="off" name="parentEmailInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="form-group" ref="childFirstName">
                  <label for="childFirstNameInput">Child's first name</label>
                  <ValidationProvider v-slot="{classes, errors}" rules="required" name="Child's first name">
                    <input v-model="form.childFirstName" type="text" placeholder="" autocomplete="off"  name="childFirstNameInput" class="form-control" v-bind:class="classes">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <b-alert variant="danger"
                  dismissible
                  :show="showDismissibleAlert"
                  @dismissed="showDismissibleAlert=false">
                  {{serverError}}
                </b-alert>
                <button type="submit" class="btn btn-warning btn-lg btn-block" :disabled="creditsAv < 1">Create profile and send questionnaire</button>
              </b-form>
            </ValidationObserver> 
          </div> 
        </div>
      </div>
    </div>

  </div>

  <Footer />
</div>

</template>

<script>
import Credits from '@/components/modules/Credits';
import accountDetails from '@/mixins/accountDetails'

export default {
  name: 'ProfileAdd',
  components: {
    Credits
  },
  mixins: [accountDetails],
  data () {
    return {
      form: {
        parentFirstName: '',
        parentLastName: '',
        parentEmail: '',
        childFirstName: ''
      },
      serverError: '',
      showDismissibleAlert: false
    }
  },

  async created() {
    this.$store.state.appSection = 'appUser'
    this.$store.state.loadingProgress = true
    await Promise.all([this.accountPersonalDetailsGet(), this.getOrganisationCredits()])
    this.$store.state.loadingProgress = false
  },

  methods: {
    showError(txt) {
      this.serverError = txt
      this.showDismissibleAlert = true
    },
    submitChecks() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            var el = this.$refs.form.refs[errors[0]["key"]].$el
            this.$scrollTo(el, 500, {offset: -100, cancelable: false})
          }, 100);
        } else {
          this.showDismissibleAlert = false
          console.log('Form validated ready to submit', this.form)
          this.$bvModal.show('modal-send')
        }
      });
    },
    async submit() {
      this.$store.state.loadingProgress = true

      // Check organisation credits right before submitting
      await this.getOrganisationCredits()
      if (this.creditsAv < 1) {
        this.alertMsgShow('Your organisation run out of credits', false)
        this.$bvModal.hide('modal-send')
        this.$store.state.loadingProgress = false
        return false
      } 

      this.$http({
        method: 'post', 
        url: this.$api.QuestionnaireProfilesCreate,
        params: {
          ...this.form
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.sendQuestionnaire(response.data)
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong. Please try again.', false)
          this.$router.push('/dashboard')
        })
    },
    sendQuestionnaire(profileId) {
      console.log(profileId)
      this.$http({
        method: 'post', 
        url: this.$api.QuestionnaireCreate,
        params: {
          profileId
        },
        headers: {'Authorization': 'bearer ' + this.$store.state.jwt } 
      })
        .then((response) => {
          console.log(response)
          this.alertMsgShow('Invite sent!', true);
          this.$router.push('/dashboard')
        })
        .catch((error) => {
          console.error('Error', error)
          this.alertMsgShow('Something went wrong. Please try again.', false)
        })
        .then(() => {
          this.$store.state.loadingProgress = false
        })
    }
  }
}
</script>
